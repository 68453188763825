<template>
    
    <div style="float:right">
        <span v-if="obj === null && creable">
            <button 
                @click="$emit('newObj')" 
                class="btn btn-md btn-default m-l-5">
                <i class="fa fa-plus"></i> {{ 'new' | transUF }}
            </button>
        </span>
        <span v-if="hasExports">
            <button 
                @click="$emit('download')" 
                class="btn btn-md btn-default m-l-5">
                <i class="fa fa-download"></i>
            </button>
        </span>
    </div>

</template>
<script>
export default {
    name: 'ButtonsTopbar',
    props: {
        title : {},
        newObjButton : {
            default : true
        },
        creable: {
            default: false
        },
        hasExports: {
            default: false
        },
        obj : {
            default: null
        }
    },
}
</script>