<template>
    <div>
        <div class="filter-bar">
            <filter-bar @setFilter="setFilter" v-if="searchable"></filter-bar>
            <div class="pagination-selector" style="float:right;">
                <buttons-topbar 
                    :obj="obj"
                    :hasExports="hasExports"
                    :creable="creable"
                    v-show="creable || hasExports"
                    @newObj="$emit('newObj')"
                    @download="$emit('download')"
                >
                </buttons-topbar>
                <column-filter v-show="filtered" ></column-filter>
            </div>
        </div>
        <actions 
            v-if="showActions && actionsDropdown !== null && !autoActions" 
            :actionsDropdown="actionsDropdown"
            :checked="checked"
        ></actions>
        <div id="loading-table-div" 
            v-if="!loaded"
            :style="{'height' : loaderHeight}" style="vertical-align:middle; text-align:center;">
            <div style="vertical-align:middle; text-align:center"> 
                <img src="/images/loading.gif" style="width:40px; margin:auto; opacity:0.8" :style="{ 'margin-top' : loaderMarginTop }" />
            </div>
        </div>
        <vuetable 
            ref="vuetable"
            :api-url="apiIndexUrl"
            :fields="columns"
            pagination-path="meta"
            :css="css.table"
            :class="{'loading-table' : !loaded }"
            :sort-order="[
                { field: sortBy, sortField: sortBy, direction: sortDirection}
            ]"
            :perPage="perPageTemp"
            :multi-sort="false"
            :append-params="moreParams"
            :no-data-template="noDataTemplate"
            @vuetable:loaded="onTableLoaded"
            @vuetable:cell-clicked="onCellClicked"
            @vuetable:pagination-data="onPaginationData"
            @vuetable:checkbox-toggled="checkboxToggle"
            @vuetable:checkbox-toggled-all="checkboxToggleAll"
        >
        </vuetable>
        <paginator-selector v-show="paginated"></paginator-selector>
        <div class="vuetable-pagination" v-show="paginated">
            <vuetable-pagination-info ref="paginationInfo"
                info-class="pagination-info"
                :info-template="transUF('Showing {from} / {to} of {total}')"
            ></vuetable-pagination-info>
            <vuetable-pagination ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage"
            ></vuetable-pagination>
        </div>
	</div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import FilterBar from './vue-table/FilterBar.vue'
import PaginatorSelector from './vue-table/PaginatorSelector.vue'
import ColumnFilter from './vue-table/ColumnFilter.vue'
import Actions from './vue-table/Actions.vue'
import ButtonsTopbar from './ButtonsTopbar.vue'

export default {
    name : 'GVueTable',
    props: {
        sortBy : {},
        columns : {},
        apiIndexUrl : {},
        perPage : { default : 100 },
        filtered : { default : true },
        searchable : { default : true },
        creable : { default : true },
        paginated : { default : true },
        sortDirection : { default : 'asc' },
        obj : { default : null },
        actionsDropdown : { default : null },
        autoActions : { default : false },
        hasExports : { default: false },
    },
    components: { 
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        FilterBar,
        PaginatorSelector,
        ColumnFilter,
        Actions,
        ButtonsTopbar
    },
    computed : {
        showActions() {
            return this.checked.length > 0
        }
    },
    methods : {
        checkboxToggle(isChecked, item) {
            var index = this.checked.indexOf(item.id);
            if (index === -1 && isChecked) {
                this.checked.push(item.id);
            } else {
                this.checked.splice(index, 1);
            }

            if (this.autoActions)
                this.$emit('doAutoAction', {
                    toggled : isChecked,
                    ids : [ item.id ]
                }
            )
        },
        checkboxToggleAll(isChecked) {
            if (this.$refs.vuetable.selectedTo.length === 0)
                this.checked = []
            else
                this.checked = this.$refs.vuetable.selectedTo

            if (this.autoActions) {
                var ids = [];
                for (var i=0; i<this.$refs.vuetable.tableData.length; i++)
                    ids.push(this.$refs.vuetable.tableData[i].id)
                this.$emit('doAutoAction', {
                    toggled : isChecked,
                    ids : ids
                })
            }
        },
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        onCellClicked (data, field, event) {
            if (event.target.nodeName !== 'A') {
                for (var i=0; i<this.columns.length; i++)
                    if (this.columns[i].name === field.name
                        && this.columns[i].cellClicked !== undefined)
                        return this.columns[i].cellClicked(this.$parent, data, field, event)
            }
        },
        setFilter (filterText) {
            if (filterText === '')
                this.moreParams = {}
            else
                this.moreParams = { filter: filterText }
            
            Vue.nextTick( () => this.$refs.vuetable.refresh() )
        },
        refresh() {
            this.$refs.vuetable.refresh()
            this.$refs.vuetable.selectedTo = []
            this.checked = []
        },
        onTableLoaded() {
            this.loaded = true
            this.noDataTemplate = this.transUF('no data')
            this.loaderHeight = this.$refs.vuetable.$el.clientHeight+'px'
            // this.loaderMarginTop = ((this.$refs.vuetable.$el.clientHeight/2)-40)+'px'
        }
    },
    data(){
        return {
            loaderHeight : '200px',
            loaderMarginTop : '160px',
            loaded : false,
            noDataTemplate : this.transUF('loading data')+'. . .',
            checked : [],
            perPageTemp : this.$props.perPage,
            moreParams : {},
            css : {
                table: {
                    tableClass: 'table table-bordered table-striped table-hover table-condensed',
                    ascendingIcon: 'fa fa-sort-up',
                    descendingIcon: 'fa fa-sort-down'
                },
                pagination: {
                    wrapperClass: 'pagination',
                    activeClass: 'active',
                    disabledClass: 'disabled',
                    pageClass: 'page',
                    linkClass: 'link',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                },
                icons: {
                    first: 'fa fa-chevron-double-left',
                    prev: 'fa fa-chevron-left',
                    next: ' fa-chevron-right',
                    last: ' fa-chevron-double-right',
                }
            }
        }
    },
    watch: {
        perPageTemp() {
            var t = this
            // t.perPage = newval
            setTimeout(function() {
                t.$refs.vuetable.reload()
            }, 100)
        }
    }
}

</script>