<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.mailinglists }">
        <div class="row">
            <div class="col-12" style="padding-top:10px; padding-bottom:10px; border:1px solid #ebebeb">

                <g-vue-table
                    ref="journalistMailinglistsTable"
                    :apiIndexUrl="'/api/journalists/'+obj.id+'/mailinglists'"
                    sortBy="name"
                    sortDirection="asc"
                    :columns="columns"
                    :obj="null"
                    :actionsDropdown="actionsDropdown"
                    @newObj="openModal()"
                ></g-vue-table>

                <add-to-mailinglists
                    ref="addToLister" 
                    addableRef="journalistMailinglistsTable"
                    :mailinglists="mailinglists"
                    :obj="obj"
                ></add-to-mailinglists>

            </div>

        </div>
    </div>
</template>

<script>
import AddToMailinglists from './lists/AddToMailinglists.vue' 
export default {
    name : 'Mailinglists',
    props : [ 'tabs', 'mailinglists' ],
    components : {
        AddToMailinglists
    },
    computed : {
        obj() { return this.$parent.obj },
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('remove from lists'),
                clicked : function(ids) {
                    t.removeFromMailinglists(ids)
                }
            }]
        }
    },
    methods : {
        openModal() {
            this.$refs.addToLister.$refs.modalAddToLister.show()
        },
        removeFromMailinglists(ids) {
            var t = this
            var obj_ids = []
            obj_ids.push(t.obj.id)
            window.axios.post('/api/journalists/remove-from-lists', {
                ids : obj_ids,
                mailinglists : ids
            }).then(function() {
                window.notify('success', 'Oggetto rimosso dalle liste selezionate')
                t.$refs.journalistMailinglistsTable.refresh()
            })
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open('/#/mailinglists/'+data.id)
                },
                visible : true,
            },{
                title : this.transUC('journalists'),
                name: '_journalists_count',
                sortField: '_journalists_count',
                visible : true
            },{
                title : this.transUC('newsletters'),
                name: '_newsletters_count',
                sortField: '_newsletters_count',
                visible : true
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true,
            }]
        }
    }
}
</script>