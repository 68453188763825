<template>
	<div>
        <g-vue-table
            ref="deletableVueTable"
            :apiIndexUrl="'/api/editorials'"
            sortBy="companyname"
            :columns="columns"
            :obj="obj"
            @newObj="$emit('newObj')"
            :actionsDropdown="actionsDropdown"
            @download="download"
            :hasExports="true"
        ></g-vue-table>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="editorials"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>

        <merge-modal 
            ref="merger" 
            mergebleRef="deletableVueTable"
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
        ></merge-modal>

        <add-to-lister 
            ref="addToLister" 
            addableRef="deletableVueTable"
            :mailinglists="mailinglists"
        ></add-to-lister>

        <remove-from-lister 
            ref="removeFromLister" 
            removableRef="deletableVueTable"
            :mailinglists="mailinglists"
        ></remove-from-lister>

	</div>
</template>

<script>
import MergeModal from './list/MergeModal.vue'
import AddToLister from './list/AddToLister.vue'
import RemoveFromLister from './list/RemoveFromLister.vue'
export default {
    name : 'EditorialsList',
    components : {
        MergeModal,
        AddToLister,
        RemoveFromLister
    },
    props : ['obj', 'regions', 'provinces', 'countries', 'mailinglists' ],
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            },{
                name : this.transUC('merge'),
                clicked : function(ids) {
                    if (ids.length > 1)
                        t.$refs.merger.show( ids )
                }
            },{
                name : this.transUC('add to lists'),
                clicked : function(ids) {
                    t.$refs.addToLister.show( ids )
                }
            },{
                name : this.transUC('remove from lists'),
                clicked : function(ids) {
                    t.$refs.removeFromLister.show( ids )
                }
            }]
        }
    },
    methods: {
        download() {
            const columns = this.$cleanColumns(this.columns)
            window.open('/api/editorials/download?cols=' + columns.join('|'))
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                visible : true,
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/editorials/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                }
            },{
                title : this.transUC('name'),
                name: 'companyname',
                sortField: 'companyname',
                dataClass: 'clickable',
                visible : true,
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/editorials/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
            },{
                title : this.transUC('city'),
                name : 'city',
                sortField: 'city',
                visible : true
            },{
                title : this.transUC('publisher'),
                name: '_publisher',
                sortField: '_publisher',
                dataClass: 'clickable',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = data.split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = '<a href="/#/publishers/'+match[1]+'" target="_blank">'+data[i].replace(match[0], '')+'</a>'
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('periodicity'),
                name : '_periodicity',
                sortField: '_periodicity',
                callback : function(data) {
                    if (data === null)
                        return null
                    data = data.split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
                visible : true
            },{
                title : this.transUC('category'),
                name : '_categories',
                sortField: '_categories',
                callback : function(data) {
                    if (data === null)
                        return null
                    data = data.split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
                visible : true
            },{
                title : this.transUC('genre'),
                name : '_genres',
                sortField: '_genres',
                callback : function(data) {
                    if (data === null)
                        return null
                    data = data.split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
                visible : true
            },{
                title : this.transUC('journ'),
                name: '_journalists_count',
                sortField: '_journalists_count',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
                callback(data) {
                    return data !== 0 ? data : ''
                }
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }],
        }
    }
}
</script>