<template>
	<div>
        <g-vue-table
        ref="deletableVueTable"
        :apiIndexUrl="'/api/mailinglists'"
        :sortBy="'name'"
        :columns="columns"
        :obj="obj"
        @newObj="$emit('newObj')"
        :actionsDropdown="actionsDropdown"
        :hasExports="true"
        @download="download"
        ></g-vue-table>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="mailinglists"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>
	</div>
</template>

<script>
export default {
    name : 'MailinglistsList',
    props : ['obj'],
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    methods: {
        download() {
            const columns = this.$cleanColumns(this.columns)
            window.open('/api/mailinglists/download?cols=' + columns.join('|'))
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/mailinglists/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
                visible : true
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable',
                visible : true,
                acaso : false,
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/mailinglists/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
            },{
                title : this.transUC('journalists'),
                name: '_journalists_count',
                sortField: '_journalists_count',
                visible : true,
                callback(data) {
                    return data !== 0 ? data : ''
                }
            },{
                title : this.transUC('newsletters'),
                name: '_newsletters_count',
                sortField: '_newsletters_count',
                visible : true,
                callback(data) {
                    return data !== 0 ? data : ''
                }
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }],
        }
    }
}
</script>
