import Vue from 'vue'
Vue.mixin({
    methods: {
        $cleanColumns(columns) {
            return columns
                .map(o => o.name)
                .filter(o => o !== "__checkbox");
                // .map(o => o.replace(/^_/, ''));
        },
    }
})