<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.blacklists }">

        <div class="row">

            <div class="col-12" style="padding-top:10px; padding-bottom:10px;">

                <g-vue-table
                    ref="journalistBlacklistsTable"
                    :apiIndexUrl="'/api/journalists/'+obj.id+'/blacklists'"
                    sortBy="_customer"
                    sortDirection="asc"
                    :columns="columns"
                    :obj="null"
                    :actionsDropdown="actionsDropdown"
                    @newObj="openModal()"
                ></g-vue-table>

                <add-to-blacklists
                    ref="addToLister" 
                    addableRef="journalistBlacklistsTable"
                    :customers="customers"
                    :obj="obj"
                ></add-to-blacklists>

            </div>
        </div>

    </div>
</template>

<script>
import AddToBlacklists from './lists/AddToBlacklists.vue' 
export default {
    name : 'Blacklists',
    props : [ 'tabs', 'customers' ],
    components : {
        AddToBlacklists
    },
    computed : {
        obj() { return this.$parent.obj },
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('remove from lists'),
                clicked : function(ids) {
                    t.removeFromBlacklists(ids)
                }
            }]
        }
    },
    methods : {
        openModal() {
            this.$refs.addToLister.$refs.modalAddToLister.show()
        },
        removeFromBlacklists(ids) {
            var t = this
            var obj_ids = []
            obj_ids.push(t.obj.id)
            window.axios.post('/api/journalists/remove-from-blacklists', {
                ids : obj_ids,
                blacklists : ids
            }).then(function() {
                window.notify('success', 'Oggetto rimosso dalle blacklist selezionate')
                t.$refs.journalistBlacklistsTable.refresh()
            })
        },
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : this.transUC('name'),
                name: '_customer',
                sortField: '_customer',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open('/#/customers/'+data.id)
                },
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true,
            }]
        }
    }
}
</script>